import React from 'react';
import styled from 'styled-components';
import EventBackground from '../../../assets/event.jpg';
import LSIcon from '../../../assets/ls-icon.svg';
import LoginForm from './LoginForm';

const LoginContainer = styled.div`
	background: #000;
	width: 100%;
	height: 100%;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	background: url(${EventBackground});
	background-size: cover;

	a {
		color: #fff;
	}
`;

const LoginFormContainer = styled.div`
	background: rgba(0,0,0,.9);
	width: 400px;
	padding: 40px;
	border-radius: 12px;
`;

const Login = (): JSX.Element => {
	return (
		<LoginContainer>
			<LoginFormContainer>
				<div style={{ textAlign: 'center' }}>
					<img src={(LSIcon as unknown) as string} alt={'Lightsnap, Inc.'} />
				</div>
				<LoginForm />
			</LoginFormContainer>
		</LoginContainer>
	);
};

export default Login;
