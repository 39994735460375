import React from 'react';
import Button from '../../components/Button';
import styled from 'styled-components';
import FormInput from '../../components/FormInput';
import { useCallback, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { devError } from '../../utils/DevError';

const FormContainer = styled.div`
	margin-top: ${(props): string => props.theme.padding(4)};
`;

const InputContainer = styled.div`
	margin-bottom: ${(props): string => props.theme.padding(4)};
`;

const LoginForm = (): JSX.Element => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [oneTimePass, setOneTimePass] = useState('');

	const handleSubmit = useCallback(async () => {
		setIsSubmitting(true);

		const signIn = firebase.functions().httpsCallable('eventOneTimeSignIn');

		try {
			const customToken = await signIn({
				credentials: oneTimePass
			});

			await firebase.auth().signInWithCustomToken(customToken.data.token);
			window.location.href = '/' + customToken.data.eventId;
		} catch (e) {
			devError(e);
			alert('Invalid event password');
		} finally {
			setIsSubmitting(false);
		}
	}, [oneTimePass]);

	return (
		<FormContainer>
			<form
				method={'post'}
				onSubmit={(event): void => {
					event.preventDefault();
					handleSubmit();
				}}
			>
				<InputContainer>
					<FormInput
						name={'oneTimePass'}
						type={'text'}
						label={'Event password'}
						placeholder={'Enter the event password'}
						width={'100%'}
						appearance={'inverted'}
						value={oneTimePass}
						onChange={(event): void => {
							setOneTimePass(event.target.value);
						}}
					/>
				</InputContainer>
				<div>
					<Button
						width={'100%'}
						appearance={'inverted'}
						disabled={oneTimePass.length === 0}
						onClick={handleSubmit}
						loading={isSubmitting}
					>
						Stream event
					</Button>
				</div>
			</form>
		</FormContainer>
	);
};

export default LoginForm;
