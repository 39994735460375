import firebase from 'firebase';

export interface EventUser {
	filmId: string;
	id: string;
}

export interface IEvent {
	created: string;
	title: string;
	eventCode: string;
	photosPerUser: number;
	public: boolean;
	users: EventUser[];
}

export class LightsnapEvent implements IEvent {
	documentId?: string;
	created: string;
	title: string;
	eventCode: string;
	photosPerUser: number;
	public: boolean;
	users: EventUser[];

	constructor(
		created: string,
		title: string,
		eventCode: string,
		photosPerUser: number,
		isPublic: boolean,
		users: EventUser[],
		documentId?: string
	) {
		this.created = created;
		this.title = title;
		this.eventCode = eventCode;
		this.photosPerUser = photosPerUser;
		this.public = isPublic;
		this.users = users;
		this.documentId = documentId;
	}

	static fromSnapshot(data: firebase.firestore.DocumentData, documentId: string): LightsnapEvent {
		const event = { ...data } as IEvent;

		return new LightsnapEvent(
			event.created,
			event.title,
			event.eventCode,
			event.photosPerUser,
			event.public,
			event.users,
			documentId
		);
	}
}