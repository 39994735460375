import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { EventUser, LightsnapEvent } from '../../../models/Event';
import { Label, LabelStyled } from '../../../components/Label';
import { LightsnapTV } from './LightsnapTV';
import { ImageStreamContext, PhotoObject, useImageStream } from '../store/ImageStream';
import firebase from 'firebase';
import QRCodeImg from '../../../../assets/QRCode.png';

interface EventChannelProps {
	event: LightsnapEvent;
}

const EventChannelStyled = styled.div`
	width: 100%;
	height: 100%;
	background: #000;
	display: flex;
	flex-direction: column;
`;

const LightsnapQRCode = styled.img`
	height: 10vh !important;
	width: 10vh !important;
`;

const QrCodeWrapper = styled.div`
	position: absolute;
	right: 16px;
	bottom: 16px;
`;

const TVMonitor = styled.div`
	margin: 32px;
	background: #3f3f3f;
	flex: 1;
	position: relative;
	border-radius: 32px;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: 100vw;
		height: 100vh;
		object-fit: contain;
		object-position: center center;
		position: relative;
		z-index: 2;
	}

	img.background {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		object-fit: cover;
		width: 100%;
		height: 100%;
		z-index: 1;
		filter: blur(22px);
		-webkit-filter: blur(22px);
	}

	> p {
		color: #929292;
		font-size: 32px;
		font-weight: 100;
	}

	${LabelStyled} {
		position: absolute;
		top: 24px;
		right: 24px;
		background-color: #de5656;
		font-size: 18px;
		padding: 12px 24px;
		border-radius: 32px;
		z-index: 3;

		&.channelName {
			font-size: 34px;
			font-weight: 300;
			background-color: rgba(0, 0, 0, 0.6);
			color: #fff;
			left: 24px;
			right: unset;
		}
	}
`;

const TVFooter = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 32px 32px;

	h3,
	p {
		font-size: 16px;
		color: #fff;
		margin: 0;
	}

	h3 {
		margin: 6px 0 0 0;
		font-size: 28px;
		font-weight: 300;

		span {
			font-weight: 700;
			font-size: 48px;
		}
	}
`;

const databaseStreams: Map<string, EventUser> = new Map<string, EventUser>();

export const EventChannel = ({ event }: EventChannelProps): JSX.Element => {
	const [state, dispatch] = useImageStream({
		bannedPhotos: [],
		photos: new Map<string, PhotoObject>(),
		currentImage: undefined
	});
	const [eventUsers, setEventUsers] = useState<EventUser[]>(event.users);

	const addPhotosToStream = (
		eventUser: EventUser,
		photoSnapshot: firebase.firestore.QuerySnapshot
	) => {
		console.log('PhotoSnapshot', photoSnapshot);

		if (!photoSnapshot.empty) {
			const docs = photoSnapshot.docs;

			console.log('PhotoSnapshot Docss', photoSnapshot.docs);

			for (const doc of docs) {
				const photoDoc = doc.data() as { path: string };
				console.log('Add Image', photoDoc.path);
				dispatch({
					type: 'addImage',
					payload: {
						beenDisplayed: false,
						photoUrl: photoDoc.path,
						userId: eventUser.id,
						filmId: eventUser.filmId
					}
				});
			}
		}
	};

	useEffect(() => {
		// eslint-disable-next-line no-console
		console.log('STARTING SLIDESHOW INTERVAL');
		const i = setInterval(() => {
			dispatch({
				type: 'showNewImage'
			});
		}, 6000);

		return () => {
			// eslint-disable-next-line no-console
			console.log('CLEARING SLIDESHOW INTERVAL');
			clearInterval(i);
		};
	}, []);

	useEffect(() => {
		if (event && event.users) {
			if (!eventUsers) {
				setEventUsers(event.users);
			} else {
				if (event.users.length !== eventUsers.length) {
					// eslint-disable-next-line no-console
					console.log('Event users updated');
					setEventUsers(event.users);
				}
			}
		}
	}, [eventUsers, event.users]);

	useEffect(() => {
		if (eventUsers && eventUsers.length > 0) {
			for (const user of eventUsers) {
				if (!databaseStreams.has(user.id)) {
					// eslint-disable-next-line no-console
					console.log('Added user to stream');
					databaseStreams.set(user.id, user);

					const initialiseStream = async (eventUser: EventUser) => {
						// Initialise stream
						const docRef = firebase
							.firestore()
							.collection('user')
							.doc(eventUser.id)
							.collection('films')
							.doc(eventUser.filmId)
							.collection('photos');

						// eslint-disable-next-line no-console
						console.log(
							`Stream initialised for user ${eventUser.id} with film: ${eventUser.filmId}`
						);
						docRef.onSnapshot((snapshot) => addPhotosToStream(eventUser, snapshot));
					};

					initialiseStream(user);
				}
			}
		}
	}, [eventUsers]);

	return (
		<EventChannelStyled>
			<ImageStreamContext.Provider
				value={{
					state,
					dispatch
				}}
			>
				<TVMonitor>
					<Label className={'channelName'}>{event.title}</Label>
					<Label>LIVE {eventUsers ? ` (${eventUsers.length} users)` : ''}</Label>
					{!state.currentImage && <p>No photos taken yet!</p>}
					{state.currentImage && (
						<>
							<img
								src={state.currentImage?.photoUrl}
								className={'background'}
								alt={'Lightsnap Event Image Blurred'}
							/>
							<img src={state.currentImage?.photoUrl} alt={'Lightsnap Event Image'} />
						</>
					)}
					<QrCodeWrapper>
						<LightsnapQRCode src={QRCodeImg} />
					</QrCodeWrapper>
				</TVMonitor>
			</ImageStreamContext.Provider>
			<TVFooter>
				<div>
					<h3>
						Join code: <span>{event.eventCode}</span>
					</h3>
				</div>
				<div>
					<LightsnapTV scale={1} />
				</div>
			</TVFooter>
		</EventChannelStyled>
	);
};
