import React from 'react';
import { render } from 'react-dom';
import Theme from './config/Theme';
import { ThemeProvider } from 'styled-components';
import Login from './pages/login';
import { BrowserRouter, Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import 'normalize.css';
import './styles/main.css';
import NotFound from './pages/error/NotFound';
import withFirebaseAuth from 'react-with-firebase-auth';
import firebase, { User } from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import { WrappedComponentProps } from 'react-with-firebase-auth/dist';
import { Loading } from './components/Loading/Loading';
import { EventTV } from './pages/event/EventTV';

const firebaseConfig = {
	apiKey: 'AIzaSyCHy-RI3v1bp_vJYJHmKLCB8G_auHnSGwY',
	authDomain: 'lightsnap.firebaseapp.com',
	databaseURL: 'https://lightsnap.firebaseio.com',
	projectId: 'lightsnap',
	storageBucket: 'lightsnap.appspot.com',
	messagingSenderId: '814475888641',
	appId: '1:814475888641:web:fed6a5ca1bdaabd3b61800',
	measurementId: 'G-RJDSJG7KEL'
};

const DarkroomContainer = document.getElementById('ls-darkroom');

interface AuthenticatedRouteProps extends Omit<RouteProps, 'component'> {
	authenticated: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	component: any;
}

const AuthenticatedRoute = ({
	authenticated,
	component: Component,
	...rest
}: AuthenticatedRouteProps): JSX.Element => (
	<Route
		{...rest}
		render={(props): React.ReactNode =>
			authenticated ? <Component {...props} /> : <Redirect to="/admin/login" />
		}
	/>
);

export const AppContext = React.createContext<User | null>(null);

const App = ({ user }: WrappedComponentProps): JSX.Element => {
	// Ignore the error.
	if (user === undefined) {
		return <Loading />;
	}

	const authenticated = !!user;

	return (
		<ThemeProvider theme={Theme}>
			<AppContext.Provider value={user}>
				<BrowserRouter>
					<Switch>
						<AuthenticatedRoute
							authenticated={authenticated}
							exact={true}
							path={'/:eventId'}
							component={EventTV}
						/>
						<Route path={'*'} component={Login} />
					</Switch>
				</BrowserRouter>
			</AppContext.Provider>
		</ThemeProvider>
	);
};

export const firebaseApp: firebase.app.App = firebase.initializeApp(firebaseConfig);
const firebaseAppAuth = firebaseApp.auth();
const providers = {
	googleProvider: new firebase.auth.GoogleAuthProvider()
};

const WithFirebaseAuth = withFirebaseAuth({
	providers,
	firebaseAppAuth
})(App);

render(<WithFirebaseAuth />, DarkroomContainer);
